/** * Main app  * * @author Romain Malauzat * @date 2014-12-26 */
require(['modules/module'], function (module) {
	'use strict';
	console.log(module);
});


require(['jquery', 'domReady','swiper', 'myswiper', 'sender', 'designgoutte', 'annimsvg', 'videojs','resize', 'TweenLite','TimelineLite','CSSPlugin', 'EasePack' ], function ($, domReady,Swiper, myswiper, send, designgoutte,annimsvg, vid, resize, TL ) {
	'use strict';

	vid.options.flash.swf = "http://www.hlsprovider.org/videojs/video-js.swf";

	function onresizegoutte(){
		var newgoutte = designgoutte.update();
		annimsvg.annim(newgoutte , 'annimbg' );
	}

	function changetelnumber(){
		console.log(document.getElementById('estenfrance').value+'  france ?');
		if(document.getElementById('estenfrance').value){
			$('a').each(function () {
				if($(this).attr('href').indexOf('tel:')>-1){
					$(this).attr('href', 'tel:+33652548997').text('06 52 54 89 97');
				}
			});
		}
	}


	var everPushedSomething = false;
	function bindpopstate(){
		if(everPushedSomething){
			return false;
		}
		everPushedSomething = true;
		window.onpopstate = function() {
		    var namepage = document.location.toString().substring((document.location.toString().lastIndexOf('/')+1));
		    namepage = namepage ? namepage : 'home';
		    console.log(' pop: ' + namepage);
		    changepage(namepage);
		};
	}


	// // Revert to a previously saved state
	// $(window).on('popstate', function(e) {
	//    var namepage = document.location.replace('/admin','').substring((document.location.replace('/admin','').lastIndexOf('/')+1));
	//    console.log(document.location+'popstate fired!'+e+'  namepage : '+namepage);
	//    /* choppe le nom de la page, meme si c'est une sous page.. => chope le dernier slash et garde le reste $(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1));  */
	//    // changepage(namepage);
	//    // return false;
	// });


	domReady(function () {
        console.info('The DOM is ready before I happen');
		mypageisready();
    });

	function mypageisready(){
		myswiper.startallswiper();
		lancegoutte();
		changetelnumber();

		$('.video-js').each(function () {
			console.log('videojs.... ');
			vid($(this)[0], { "controls": true, "autoplay": false, "preload": "auto", "width": "auto", "height": "auto" });
		});

		$('.principale a, .linkhome, .listlink a').unbind('click');
		$('.principale a, .linkhome, .listlink a').bind('click',adlinkjs);
	}

    function lancegoutte(){
		//console.log('lancegoutte : '+$('.annimbg').length);
		if($('.annimbg').length){
			var goutte =  designgoutte.makegoute('.annimbg');
		//	console.log(goutte+' y a une goutte');
			annimsvg.annim(goutte,'annimbg');
			resize.addlistner(onresizegoutte);
		}else{
			resize.removelistner(onresizegoutte);
		}
    }

    var mouvecomplet = function (){
		console.log('mouvecomplet');

		$('.site-wrap').removeAttr('style').removeClass('onmove').find('.old').remove();
		$('.site-wrap .new').removeAttr('class');

		mypageisready();
    };

    function mouvewarp(newpage){
		$('.fixedbg').removeClass('fixedbg');
		$('.sectionbg').removeClass('sectionbg');
		var old = $('.site-wrap').html();
		// if($('.listlink').length){
		// 	var timeline = new TLL({});
		// 	var li = $('.listlink li');
		// 	TL.set(li, {perspective:800});
		// 	TL.set(li, {transformStyle:"preserve-3d"});
		// 	timeline.staggerTo(li, 0.2,{rotationY :-90,opacity :0, ease : 'Sine.easeOut' }, -0.1);
		// }else{
			$('.site-wrap').html('<div class="old">'+old+'</div><div class="new">'+newpage+'</div>').addClass('onmove');
			TL.from('.site-wrap', 1, {x:-$(window).width(),ease:'Back.easeOut', onComplete:mouvecomplet});
		//}
    }



    function changepage(namepage){
		$('.shield').addClass('show');
		var linkcible = '/inc/page/'+namepage+'.php';
		console.log(namepage+'  o o '+(namepage==='home'));
		if(namepage==='home'){
			$('.linkhome').addClass('waiting');
			console.log($('.linkhome').attr('class'));
		}else{
			$('nav a, .listlink a').each(function () {
				console.log($(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1))+'==='+namepage);
				/* choppe le nom de la page, meme si c'est une sous page.. => chope le dernier slash et garde le reste $(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1));  */
				if($(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1))===namepage){
					$(this).addClass('waiting');
				}
			});
		}

		send.getData({
		successCallback: function(retour) {

			$('.shield').removeClass('show');
			mouvewarp(retour);
			console.log($('.waiting').attr('href')+" -- $('.waiting').attr('href')");

			$('.selected').removeClass('selected');
			var myul = $('.waiting').parent().parent();
			if(myul.hasClass('secondaire')){
				myul.parent().addClass('selected');
				myul.addClass('selected');
			}
			$('#encour').val(namepage);
			$('.waiting').removeClass('waiting').parent().addClass('selected');

			console.log(myul.hasClass('secondaire')+' || '+$('.principale .selected').find('.secondaire').length+(myul.hasClass('secondaire') || $('.principale .selected').find('.secondaire').length));
			if(myul.hasClass('secondaire') || $('.principale .selected').find('.secondaire').length ){
				$('body').addClass('submenu');
			}else{
				$('body').removeClass('submenu');
			}

			$('.nav-trigger').prop( 'checked', false );
			if(namepage==='home') {
				$('body').addClass('homepage');
			}else{
				$('body').removeClass('homepage');
			}

		},
		Error:function(){
			$('.shield').removeClass('show');
			$('.waiting').removeClass('waiting');
			$('.site-wrap').html('page introuvable, désolé.');
			console.log('ErrorErrorErrorErrorErrorErrorErrorErrorErrorErrorError');
		},
		Type: 'GET',
		apiURL: linkcible
		});
    }/**/

    var adlinkjs = function (){

		// if($(this).parent().hasClass('selected') || $(this).hasClass('selected')){
		// 	return;
		// }
		bindpopstate();
		$(this).addClass('waiting');

		var namepage = $(this).hasClass('linkhome') ? 'home' : $(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1));
		changepage(namepage);
		history.pushState({id: namepage}, '', $('.waiting').attr('href'));

		return false;
    };

    $('.principale a, .linkhome, .listlink a').bind('click',adlinkjs);

	// $('.principale a, .linkhome, .listlink a').click(function() {
	// 	//$(this).addClass('waiting');

	// 	var namepage = $(this).hasClass('linkhome') ? 'home' : $(this).attr('href').replace('/admin','').substring(($(this).attr('href').replace('/admin','').lastIndexOf('/')+1));
	// 	changepage(namepage);
	// 	history.pushState({id: namepage}, '', $('.waiting').attr('href'));

	// 	return false;

	// });
});
define("main", function(){});

