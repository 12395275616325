/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('myswiper', ['swiper'], function (Swiper) {
	'use strict';


	function creatswip(cible){
		console.log('########### arrggg gg ');
		//var swipcible = cible ? (cible + ' .swiper-container') : '.swiper-container';
		var swipcible = cible ? (cible.indexOf('swiper-container') ? cible : (cible + ' .swiper-container')) : '.swiper-container';
		console.log('cible : '+cible+'  swipcible : '+swipcible);
		//console.log('creatswip  admin = '+document.getElementById('admin').value);
		//var myswiper = new Swiper('.swiper-container',{
		var myswiper = new Swiper(swipcible,{
			//Your options here:
			mode:'horizontal',
			loop: (document.getElementById('admin').value ? false:true),
			autoplay: (document.getElementById('admin').value ? false:3000),
			calculateHeight:true,
	        //effect: (document.getElementById('admin').value ? false:'fade'),
			//onSlideChangeEnd : function(swiperhere) {
			// 	console.log('active'+swiperhere.activeIndex);
			// 	$('.swiper-slide').eq(swiperhere.activeIndex).focus();
			// }
			//etc..
		});
		return myswiper;
	}

	function startallswiper(){
		var swiper;
		console.log($('.swiper-container').length+'??  startallswiper #########################  swiper  :'+swiper);
		if($('.swiper-container').length>1){
			swiper = [];
			$('.swiper-container').each(function(index) {
				//console.log($(this).html());
				if($(this).find('.swiper-slide').length>1){
					swiper.push(creatswip('.swiper-container:eq('+index+')'));
				}
			//	$(this).attr('data-swip',creatswip());
			});
		}else{
			console.log(($('.swiper-container').length)+'so ??? combien de  swiper  ??');
			if($('.swiper-container').length){
				swiper = creatswip();
			}
		}
		console.log('startallswiper #########################  swiper  :'+swiper);

		return swiper;
    }


    return {
        creatswip:creatswip,startallswiper:startallswiper
    };

});

define("modules/myswiper", function(){});

