/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('isElementInViewport', [], function () {
	'use strict';

	function isvisible(el) {

		//special bonus for those using jQuery
	    // if (typeof jQuery === "function" && el instanceof jQuery) {
	    //     el = el[0];
	    // }

	    var $elem = el;
	    var $window = $(window);

	    var docViewTop = $window.scrollTop();
	    var docViewBottom = docViewTop + $window.height();

	    var elemTop = $elem.offset().top;
	    var elemBottom = elemTop + $elem.height();
	    // console.log(' elemTop : '+elemTop+' elemBottom : '+elemBottom);
	    // console.log('docViewTop : '+docViewTop+'  docViewBottom : '+docViewBottom);
	    // return (
	    //     rect.top >= 0 &&
	    //     rect.left >= 0 &&
	    //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
	    //     rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
	    // );
		//console.log((( elemTop >= docViewTop) && (elemTop < docViewBottom))+' ---- '+elemTop+' >= '+docViewTop+') && ('+elemTop+' < '+docViewBottom+')) || (('+elemBottom+' >= '+docViewTop+') && ('+elemBottom+' <= '+docViewBottom+'  = '+((( elemTop >= docViewTop) && (elemTop <= docViewBottom)) || ((elemBottom >= docViewTop) && (elemBottom <= docViewBottom))));
		return ((( elemTop >= docViewTop) && (elemTop < docViewBottom)) || ((elemBottom >= docViewTop) && (elemBottom <= docViewBottom)));

		// var $elem = el;
	 //    var $window = $(window);

	 //    var docViewTop = $window.scrollTop();
	 //    var docViewBottom = docViewTop + $window.height();

	 //    var elemTop = $elem.offset().top;
	 //    var elemBottom = elemTop + $elem.height();

	 //    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	}

    return {
        isvisible:isvisible
    };

});

define("modules/isElementInViewport", function(){});

