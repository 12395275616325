/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('resize', [], function () {
	'use strict';

	var delayresize;
	var tabfunc = [];
	var arrayLength;
	var activeated = false;

	function reze(){
		clearInterval(delayresize);
		arrayLength = tabfunc.length;
		for (var i = 0; i < arrayLength; i++) {
			tabfunc[i]();
		}
	}

	function activate(){
		//console.log(activeated +' : activeated');
		if(activeated){
			return;
		}
		activeated = true;
		$(window).resize(function() {//////// quand on change la taille de fenetre
			clearInterval(delayresize);
			delayresize = setInterval(reze,500);
		});
	}

	function addlistner(func){
	//	console.log(tabfunc.length);
		tabfunc.push(func);
	//	console.log(tabfunc.length);
		activate();
	}
	function removelistner(func){
	//	console.log(func);
		var indexfunc = tabfunc.indexOf(func);
		tabfunc.splice(indexfunc, 1);
	}

    return {
        addlistner:addlistner,
        removelistner:removelistner
    };

});

define("modules/resize", function(){});

