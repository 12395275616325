/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('xmlcreator', ['jquery', 'removeDiacritics'], function ($, removeDiacritics) {
	// Strict mode to prevent sloppy JS
	'use strict';
    function creatnav() {
        var xmlnav = '';
		$('nav .principale li a:not(.linksecondaire)').each(function () {
			var name = $(this).text();
			console.log(document.getElementById('addpagetxt').value+'  addpagetxt');
			if(name && name!==document.getElementById('addpagetxt').value && name!==document.getElementById('addfreepagetxt').value){
				xmlnav = xmlnav + addnod('pagename',name)+addnod('pageurl',removeDiacritics.remove(name));
			}
		});
		xmlnav = '<?xml version="1.0" encoding="UTF-8"?>\n<DATA>\n'+xmlnav+'\n</DATA>';
		return xmlnav;
    }
    function creatsousnav() {
        var xmlnav = '';
		$('.principale li.selected ul.secondaire li a').each(function () {
			var name = $(this).text();
			console.log(document.getElementById('addsouspagetxt').value+'  addsouspagetxt');
			if(name && name!==document.getElementById('addsouspagetxt').value){
				xmlnav = xmlnav + addnod('articlename',name)+addnod('articleurl',removeDiacritics.remove(name));
			}
		});
		xmlnav = '<?xml version="1.0" encoding="UTF-8"?>\n<DATA>\n'+xmlnav+'\n</DATA>';
		return xmlnav;
    }
    function addnod(nodename,info){
		var node =  '\t<'+nodename+'><![CDATA[';
		node = node + info;
		node = node + ']]></'+nodename+'>\n';
		return node;
	}

    return {
        creatnav: creatnav, creatsousnav: creatsousnav
    };

});

define("modules/xmlcreator", function(){});

