/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('scrollStopped', [], function () {
	'use strict';


	function addlistner(cible, callback){
		var $this = $(cible);
		$this.scroll(function() {
			if ($this.data('scrollTimeout')) {
              clearTimeout($this.data('scrollTimeout'));
            }
            $this.data('scrollTimeout', setTimeout(callback,250));
		});
	}


    return {
        addlistner:addlistner
    };

});

define("modules/scrollStopped", function(){});

