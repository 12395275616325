/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('designgoutte', ['jquery', 'getpos', 'clock'], function ($, getpos, clock) {
	'use strict';
	//var TheTimeis = 0;

	var namecont;

	var TpsShort1 = 1;
	var TpsShort2 = 2;
	var TpsShort3 = 3;
	var OutElastic = 'easeOutElastic';
	var InOutExpo = 'easeInOutExpo';
	var TwType1 = 'easeInOutExpo';
	var TwType2 = 'easeOutElastic';
	var InExpo = 'easeInExpo';
	var OutBounce = 'easeOutBounce';


	var conteneurgoutte;
	var largeurgoute;
	var conteneurHeight;
	var conteneurWidth;
	var oldconteneurHeight;
	var oldconteneurWidth;
	/*/////// DEBUT A DROITE*/
	var XOG = 0;
	var	YOG = 0;
	/*////////// END A GAUCHE*/
	var XEG =  0;
	var YEG;
	var HG;

	var M;
	var centre;
	var troisquart;
	var quart;
	var AngleG;
	var P1;
	var P2;
	var P3;

	var B1P1;
	var B1P3;
	var B1P4;
	var B2P1;
	var B2P2;

	var tabformul = new Array([]);

	function initvar(cible){
		namecont = cible;
		conteneurgoutte = $(cible);
		updatevar();
		$(cible).append('<svg version="1.1" class="annimsvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="'+conteneurWidth+'px" height="'+conteneurHeight+'px" viewBox="0 0 '+conteneurWidth+' '+conteneurHeight+'" enable-background="new 0 0 '+conteneurWidth+' '+conteneurHeight+'" xml:space="preserve"><path class="mypath" d=""/></svg>');
	}

	function makegoute(cible){
		initvar(cible);
		return remplitabgoutte();
	}

	function updatevar(){
		//console.log('updatevar'+conteneurWidth);
		oldconteneurHeight = conteneurHeight ? conteneurHeight : conteneurgoutte.height();
		oldconteneurWidth = conteneurWidth ? conteneurWidth : conteneurgoutte.width();
		conteneurHeight = conteneurgoutte.height();
		conteneurWidth = conteneurgoutte.width();
		largeurgoute = conteneurHeight/2;

		//console.log('conteneurHeight : '+conteneurHeight);

		/*////////// END A GAUCHE*/
		XEG =  0;
		YEG = YOG +largeurgoute;
		HG =  conteneurWidth*0.75;

		M = getpos.getmilieu(XOG,YOG,XEG,YEG);
		troisquart = getpos.getmilieu(M[0],M[1],XEG,YEG);
		quart = getpos.getmilieu(XOG,YOG,M[0],M[1]);

		AngleG = getpos.getangle(XOG,YOG,XEG,YEG)-90;
		/*////// Find point */
		P2 = getpos.getxycercle(M[0],M[1],HG,AngleG);
		P3 = getpos.getxycercle(troisquart[0],troisquart[1],HG*0.75,AngleG);
		P1 = getpos.getxycercle(quart[0],quart[1],HG*0.75,AngleG);

		/*////// Find besier*/
		centre = getpos.getmilieu(M[0],M[1],P2[0],P2[1]);/*//// trouve le centre*/
		B1P1 = getpos.getmilieu(M[0],M[1],centre[0],centre[1]);/*//// trouve le quart*/
		B2P1 = getpos.getxycercle(quart[0],quart[1],HG*0.5,AngleG);
		B2P2 = getpos.getxycercle(quart[0],quart[1],HG,AngleG);
		B1P3 = getpos.getxycercle(troisquart[0],troisquart[1],HG,AngleG);
		B1P4 = getpos.getxycercle(troisquart[0],troisquart[1],HG*0.5,AngleG);

	}

	function sizesvg(){
		document.getElementsByClassName(namecont.replace('.',''))[0].getElementsByClassName('annimsvg')[0].setAttribute('viewBox','0 0 '+conteneurWidth+' '+conteneurHeight);
		$(namecont).find('.annimsvg').attr('width',conteneurWidth).attr('height',conteneurHeight).attr('enable-background','new 0 0 '+conteneurWidth+' '+conteneurHeight);

	}



	function NewObj(VALDEP,VALEND,TPSDEP,DUREE,EASE,CALLBACK){
		var tb = VALDEP+','+VALEND+','+TPSDEP+','+DUREE+','+EASE;
		this.valactuel = VALDEP ;
		this.CALLBACK = CALLBACK;
		this.tabvar = tb ;
		this.tpsdep = TPSDEP;
		this.tpsend = TPSDEP+DUREE;
	}

	function getactualtime(){
		return clock.gettime()/1000;
		//return TheTimeis/1000;
	}

	function creaformul(VALDEP,VALEND,DUREE,EASE,CALLBACK,DELAY){
		var TPSDEP = getactualtime();
		if(DELAY){
		TPSDEP = TPSDEP + DELAY;
		}
		var obj = new NewObj(VALDEP,VALEND,TPSDEP,DUREE,EASE,CALLBACK);
		return obj;
	}

	function remplitabgoutte(){
		//var x = creaformul(M[0],  XOG,  TpsShort1, InOutExpo,Tombe1);////Tombe1 = callback apres la goutte
		var x = creaformul(M[0],  XOG,  TpsShort1, InOutExpo, Tombe1);
		var y = creaformul(M[1],  YOG,  TpsShort1, InOutExpo);
		tabformul[0]=[x,y];
		/*//tabformul.push([x,y]);*/

		var x1 = creaformul(M[0],  B1P1[0],  TpsShort2, OutElastic);
		var y1 = creaformul(M[1],  B1P1[1],  TpsShort2, OutElastic);
		var x2 = creaformul(M[0],  B2P1[0],  TpsShort2, OutElastic);
		var y2 = creaformul(M[1],  B2P1[1],  TpsShort2, OutElastic);
		x = creaformul(M[0], P1[0],      TpsShort2, OutElastic);
		y = creaformul(M[1], P1[1],      TpsShort2, OutElastic);
		tabformul[1]=[x1,y1,x2,y2,x,y];
		/*//tabformul.push([x1,y1,x2,y2,x,y]);*/

		x1 = creaformul(M[0],  P1[0],    TpsShort2, OutElastic);
		y1 = creaformul(M[1],  P1[1],    TpsShort2, OutElastic);
		x2 = creaformul(M[0],  B2P2[0],  TpsShort2, OutElastic);
		y2 = creaformul(M[1],  B2P2[1],  TpsShort2, OutElastic);
		x = creaformul(M[0], P2[0],      TpsShort2, OutElastic);
		y = creaformul(M[1], P2[1],      TpsShort2, OutElastic);
		tabformul[2]=[x1,y1,x2,y2,x,y];

		x1 = creaformul(M[0],  B1P3[0],  TpsShort2, OutElastic);
		y1 = creaformul(M[1],  B1P3[1],  TpsShort2, OutElastic);
		x2 = creaformul(M[0],  P3[0],    TpsShort2, OutElastic);
		y2 = creaformul(M[1],  P3[1],    TpsShort2, OutElastic);
		x = creaformul(M[0], P3[0],      TpsShort2, OutElastic);
		y = creaformul(M[1], P3[1],      TpsShort2, OutElastic);
		tabformul[3]=[x1,y1,x2,y2,x,y];

		x1 = creaformul(M[0],  B1P4[0],  TpsShort2, OutElastic);
		y1 = creaformul(M[1],  B1P4[1],  TpsShort2, OutElastic);
		x2 = creaformul(M[0],  B1P1[0],  TpsShort2, OutElastic);
		y2 = creaformul(M[1],  B1P1[1],  TpsShort2, OutElastic);
		x = creaformul(M[0],  XEG,       TpsShort1, InOutExpo);
		y = creaformul(M[1],  YEG,       TpsShort1, InOutExpo);
		tabformul[4]=[x1,y1,x2,y2,x,y];

		return tabformul;
	}

	function lacheElastic(){
		////// besier gauche
		tabformul[1][1] = creaformul(tabformul[1][1].valactuel,  0,  1, TwType2);
		tabformul[1][3] = creaformul(tabformul[1][3].valactuel,  0,  1, TwType2);
		//console.log(tabformul[1][1].valactuel);
	}

	function Tombe1(){
		////// HAut de goute
		tabformul[1][1] = creaformul(tabformul[1][1].valactuel, tabformul[1][1].valactuel*2,  TpsShort3, InExpo,lacheElastic);
		tabformul[1][3] = creaformul(tabformul[1][3].valactuel, tabformul[1][1].valactuel*3,  TpsShort2, InExpo);
		tabformul[1][4] = creaformul(tabformul[1][4].valactuel, conteneurWidth,  TpsShort2, InOutExpo);
		tabformul[2][0] = creaformul(tabformul[2][0].valactuel, conteneurWidth,  TpsShort2, InOutExpo);
		tabformul[2][2] = creaformul(tabformul[2][2].valactuel, conteneurWidth,  TpsShort2, InOutExpo);

		/////// BAS DE LA GOUTTE
		tabformul[4][5] = creaformul(tabformul[4][5].valactuel,  conteneurHeight,  TpsShort2, OutBounce);//////////////// Y
		tabformul[4][3] = creaformul(tabformul[4][3].valactuel,  conteneurHeight,  TpsShort2, OutBounce,undefined,0.5); //Y2
		tabformul[4][1] = creaformul(tabformul[4][1].valactuel,  conteneurHeight,  TpsShort2, OutBounce,undefined,0.8); //Y2

		tabformul[3][4] = creaformul(tabformul[3][4].valactuel,  conteneurWidth,  TpsShort1, TwType1,undefined,1);
		tabformul[3][5] = creaformul(tabformul[3][5].valactuel,  conteneurHeight,  TpsShort1, OutBounce,undefined,1);
		tabformul[3][0] = creaformul(tabformul[3][0].valactuel,  conteneurWidth,  TpsShort1, OutBounce,undefined,1);
		tabformul[3][1] = creaformul(tabformul[3][1].valactuel,  conteneurHeight,  TpsShort1, OutBounce,undefined,1);


		tabformul[3][2] = creaformul(tabformul[3][2].valactuel,  conteneurWidth,  TpsShort1, TwType1,undefined,1);
		/////// HAUT DE LA GOUTTE
		tabformul[1][5] = creaformul(tabformul[1][5].valactuel,  YOG,  TpsShort1, TwType1,undefined,1);
		tabformul[2][4] = creaformul(tabformul[2][4].valactuel,  conteneurWidth,  TpsShort1, TwType1,undefined,1);
		tabformul[2][5] = creaformul(tabformul[2][5].valactuel,  YOG,  TpsShort1, TwType1,undefined,1);

	}

	function actubg(){

		var numRand1 = Math.floor(Math.random()*101);
		var numRand2 = Math.floor(Math.random()*101);
		var numRand3 = Math.floor((Math.random()*10)+1);
		var numRand4 = Math.floor((Math.random()*10)+1);
		var xendX1 =  conteneurWidth*(numRand1/100);
		var xendX2 =  conteneurWidth*(numRand2/100);
		var tpsx1 = TpsShort1+(numRand3/10);
		var tpsx2 = TpsShort2+(numRand4/10);

		tabformul = [];

		var x = creaformul(0,  0,  TpsShort2, InOutExpo);
		var y = creaformul(0,  0,  TpsShort1, InOutExpo);

		tabformul.push([x,y]);/////0

	//	console.log(conteneurWidth+': conteneurWidth ///   conteneurWidth : '+conteneurWidth);
		x = creaformul(oldconteneurWidth,  conteneurWidth,  TpsShort1, InOutExpo);
		y = creaformul(0,  0,  TpsShort1, InOutExpo);

		tabformul.push([x,y]);/////1

		x = creaformul(oldconteneurWidth,  conteneurWidth,  TpsShort1, InOutExpo);
		y = creaformul(oldconteneurHeight,  conteneurHeight,  TpsShort1, InOutExpo);

		tabformul.push([x,y]);/////2


		var x1 = creaformul(xendX2, xendX1,  tpsx1, 'easeInOutExpo',undefined, 0.5);
		var y1 = creaformul(oldconteneurHeight,  conteneurHeight,   TpsShort2, OutElastic,undefined, 0.4);
		var x2 = creaformul(xendX1, xendX2,  tpsx2, 'easeInOutExpo',undefined, 0.7);
		var y2 = creaformul(oldconteneurHeight,  conteneurHeight,  tpsx2, 'easeOutElastic',undefined,  0.6);

		x = creaformul(0,  0,  TpsShort2, InOutExpo);
		y = creaformul(oldconteneurHeight,  conteneurHeight,  TpsShort1, InOutExpo );

	//	console.log(tabformul);
		tabformul.push([x1,y1,x2,y2,x,y]);	/////3
		return tabformul;
	}

	function update(){
		updatevar();
		sizesvg();
		return actubg();
	}

    return {
        makegoute:makegoute,update:update
    };

});

define("svgbg/designgoutte", function(){});

