/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('clock', [], function () {
	'use strict';
	var thetimeis = 0;
	function ticktak(fps){
		var inc = fps ? fps : 40;
		thetimeis += inc;
	}

	function gettime(){
		return thetimeis;
	}

	function reset(){
		thetimeis = 0;
	}

    return {
        ticktak:ticktak,
        gettime:gettime,
        reset:reset
    };

});

define("svgbg/clock", function(){});

