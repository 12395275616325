/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('adminswiper', ['jquery', 'sender', 'swiper', 'myswiper' ,'Sortable', 'tinyMCE','isElementInViewport','scrollStopped'], function ($,send,Swiper, creatmyswiper,Sortable,tinymce, isElementInViewport, scrollStopped) {
	'use strict';
	var localswiper;
    var bindedaction;

	var clickimgswiper = function (){
		if(!$(this).hasClass('used')){
			addimg($(this).find('img').attr('src'));
			$(this).addClass('used');
		}else{
			removeimg($(this).find('img').attr('src'));
			$(this).removeClass('used');
		}
    };

    var clickimglink = function (e){
		addimglink($(this),e);
    };

    var clickimgbg = function (e){
		addimgbg($(this),e);
    };


	function inittinymce(option){
		/*jshint camelcase: false */
		tinymce.init({
			    selector: option.cible,
			    setup: function(editor) {
			        editor.on('blur', function() {
						sendit();
			        });
			    },
			    inline: true,
			    browser_spellcheck : true,
			    //valid_elements : "a[href|target=_blank],strong/b,p",
				plugins: ["autolink link spellchecker"],
				toolbar: false,
				menubar : false
		});
		 /*jshint camelcase: true */
	}

	inittinymce({cible : '.legend'});

	function creatvignette(cible){
		$(cible).after('<div class="gallery-thumbs"></div>');

		$(cible+' .swiper-slide').each(function () {
			var src = $(this).find('img').attr('src');
			src = document.getElementById('arewelocal').value ? src : src.replace(/.([^.]*)$/,'-mini.'+'$1');
			$('.gallery-thumbs:last').append('<div class="thumbs"><img src="'+src+'"></div>');
		});

		Sortable.create($('.gallery-thumbs:last').get(0),{
			animation: 150,
			onEnd: function (evt) {
				swapElements($('.swiper-slide'), evt.oldIndex, evt.newIndex);
				localswiper.update();
				sendit();
		    }
		});
	}




	function swapElements(siblings, subjectIndex, objectIndex) {
	    // Get subject jQuery
	    var subject = $(siblings.get(subjectIndex));
	    // Get object element
	    var object = siblings.get(objectIndex);
	    // Insert subject after object
	    if(subjectIndex<objectIndex){
			subject.insertAfter(object);
	    }else{
			subject.insertBefore(object);
	    }
	}

	function returnslideindex(src){
		var ret;
		$('.swiper-container .swiper-slide').not('.swiper-slide-duplicate').each(function (index) {
			if(src===$(this).find('img').attr('src')){
					ret = index;
			}
		});
		return ret;
	}



	$('#admin-trigger').click(function(){
		console.log('click admin');
		if(bindedaction!==clickimgswiper){
			console.log('diferent');
			bindimg(clickimgswiper);
		}
	});

	$('#link-trigger').click(function(){
		console.log('click link');
		if(bindedaction!==clickimglink){
			console.log('diferent');
			$('.used').removeClass('used');
			bindimg(clickimglink);
		}
	});

	$('#bg-trigger').click(function(){
		console.log('click link');
		if(bindedaction!==clickimgbg){
			console.log('diferent');
			$('.used').removeClass('used');
			bindimg(clickimgbg);
		}
	});

	function init(myswiper) {
		console.log('init'+myswiper);
		localswiper = myswiper;

		initvignette('.swiper-container:eq(0)');
		addclasslistimg('.swiper-container:eq(0)');

		 bindimg(clickimgswiper);

    }



    function bindimg(action){
		bindedaction = action;
		$('.listimgserveur li').unbind('click');
		$('.listimgserveur li').bind('click', action);
    }

    function initvignette(swipercible){
		if($(swipercible+' .swiper-slide').length){
			creatvignette(swipercible);
		}
    }

    function getvisiblesectionindex(){
		var sectioncible = null;
		$('main section').each(function (index) {
			if(isElementInViewport.isvisible( $(this) )){
				sectioncible = index;
			}
		});
		return sectioncible;
    }

    scrollStopped.addlistner(window,function(){
		console.log('stoped getvisiblesectionindex()'+getvisiblesectionindex());
		if(!$('.swiper-container').length){
			console.log('pas de swiper');
			return false;
		}
		if($('main section').length){
			console.log('y a des sections ');
			addclasslistimg('main section:eq('+getvisiblesectionindex()+')');
		}else{//// si on n'a pas mis de section
			addclasslistimg('main');
		}
	});

    function addclasslistimg(swipercible){
		$('.used').removeClass('used');
		//$('.swiper-container .swiper-slide').each(function (index) {
		$(swipercible+' .swiper-slide').each(function (index) {
			var src = $(this).find('img').attr('src');
			$('.listimgserveur li').each(function () {
				if(src===$(this).find('img').attr('src').replace('-mini','')){
					$(this).index(index);
					$(this).addClass('used');
				}
			});
		});
    }


	function addimgbg(btn){
		var src = btn.find('img').attr('src').replace('-mini.','.');
		console.log(src);
		if($('main .editable section').hasClass('fixedbg')){
			if($('section').eq(0).find('img').attr('src')===src){
				$('section').removeClass('fixedbg').eq(0).find('img').remove();
			}else{
				$('section').eq(0).find('img').attr('src',src).attr('data-mce-src', src);
			}
			sendit();
			return false;
		}
		if(!$('section').length){
			var content = $('main .editable').html();
			$('main .editable').html('').find('section').addClass('fixedbg');
			$('main .editable').append('<section class="sectionbg"><div class="contbg"><img src="'+src+'"  alt="bg"></div></section><section>'+content+'</section>');
		}else{
			$('section').addClass('fixedbg').eq(0).append('<div class="contbg"><img src="'+src+'"  alt="bg"></div>').addClass('sectionbg');
		}

		sendit();
	}

	function addimglink(btn,e){

		var src = document.getElementById('arewelocal').value ? btn.find('img').attr('src') : btn.find('img').attr('src').replace('-mini.','-medium.');

		if(!$('.listlink').length){
			var where = $('#main .editable');
			if($('main section').length){///// y'a des sections
				where = $('section').eq(getvisiblesectionindex());
			}
			where.append('<ul class="listlink"></ul>');
		}
		var newdiv = '<li><div class="conttxt"><div class="txt"><h2>titre</h2><h3>sous titre</h3><a href="#">View Project</a></div></div><figure class="parallax-wrapper"><div class="contimg"><img src="'+src+'"></div></figure></li>';

		var offset = btn.offset();
	    var top = ((e.pageY - offset.top)<(btn.height()/2)) ? true : false;

	    if(top){
			$('.listlink').prepend(newdiv);
		}else{
			$('.listlink').append(newdiv);
		}

		//console.log('addimglink so..... send it : ');

//		inittinymce({cible : '.listlink:last'});
		sendit();
	}

    function addimg(src){


		if(!$('.swiper-container').length){
			console.log('y a pas de slider donc creat slider et y a des sections ? '+$('main section').length);
			var where = $('#main');
			if($('main section').length){///// y'a des sections
				where = $('section').eq(getvisiblesectionindex());
				console.log('getvisiblesectionindex() = '+getvisiblesectionindex());
				if(where.find('.blockvideo').length){
					where = where.find('.blockvideo');
				}
			}
			where.prepend('<div class="swiper-container"><div class="swiper-wrapper"></div></div>');
			console.log('swiper is down ? '+localswiper);
		}
		if(!localswiper){//// on a pas activé le swiper
			console.log('swiper is down');
			localswiper = creatmyswiper.creatswip();
			init(localswiper);
			initvignette('.swiper-container:eq(0)');
			//creatvignette();
		}
		console.log(localswiper+'  ?? ');
		localswiper.appendSlide('<div class="swiper-slide"><div class="legend">'+document.getElementById('addlegende').value+'</div><img src="'+src.replace('-mini','')+'" alt=""></div>');
		localswiper.update();
		$('.gallery-thumbs').append('<div class="thumbs"><img src="'+src+'"></div>');

		//inittinymce({cible : '.legend'});
		sendit();
    }

    function removeimg(src){


		localswiper.slideTo(0);
		var removeindex = returnslideindex(src.replace('-mini',''));
		console.log(removeindex);
		$('.swiper-container').find('.swiper-slide').eq(removeindex).remove();
		$('.gallery-thumbs .thumbs').eq(removeindex).remove();
		//localswiper.removeSlide(returnslideindex(src.replace('-mini','')));

		if(($('.swiper-container .swiper-slide').length)<=0){
			console.log(' myswiper.destroy ');
			localswiper.destroy();
			localswiper = undefined;
		//	console.log('localswiper is ?? : '+localswiper);
			$('.swiper-container').remove();
		}else{
			console.log('update');
			localswiper.update();
		}
		sendit();
    }

    function sendit(){

		console.log('send it');
		$('.shield').addClass('show');

		var clean = $('main').clone();
		clean.find('.swiper-slide-duplicate').remove();
		clean.find('.gallery-thumbs').remove();
		clean.find('div').removeAttr('style');
		clean.find('div').removeClass('swiper-slide-visible swiper-slide-active mce-content-body mce-edit-focus newlegend');
		clean.find('.legend').removeAttr('id');
		clean.find('.legend').removeAttr('contenteditable');
		clean.find('.legend').removeAttr('spellcheck');
		clean.find('div').removeAttr('data-swip');
		clean.find('.fixedbg img').removeAttr('data-mce-src');
		clean.find('.cut').remove();
		clean.find('ul').each (function () {
		  var node = $(this);
		  node.html (node.find ('> *').detach ());
		});
		console.log(clean.find('ul').html());
		clean.find('.listlink li').removeAttr('data-swip').removeAttr('class');

		clean.find('.legend').each(function () {
			if($(this).text()===document.getElementById('addlegende').value){
				$(this).addClass('hide');
			}else{
				$(this).removeClass('hide');
			}
		});

		send.getData({
	        successCallback: function(retour) {
				$('.shield').removeClass('show');
				console.log(retour+'outside callback');
		    },
	        Type: 'POST',
	        Data: {'content': $.trim(clean.html()), 'fileurl' : '../page/'+document.getElementById('encour').value+'.php'},
	        dataType: 'html',
	        apiURL: '/inc/fonction/ModifData.php'
	    });
	}

    return {
        init:init, addimg:addimg, removeimg: removeimg, sendit:sendit
    };

});

define("modules/adminswiper", function(){});

