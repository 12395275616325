/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('annimsvg', ['milkytween', 'animationFrame', 'clock'], function (milkytween, AnimationFrame, clock) {
	'use strict';
	AnimationFrame.shim();
	var FrameRate = 40;
	var animationFrame = new AnimationFrame(40);
	var tabformul;
	var AnniEnCours = false;

	var cibleannim;

	//var fpsMeter = new FpsMeter();
	var currentpath;
	var combiendeidentique = 0;
	/*///////////////////########### PARCOUR LE TABLEAU ET RECRACHE UN STRING QUI FAIT LA FORME ############////////////////////*/
	function creapath(Forme){
		var Path = '';
		var L = Forme.length;
		for(var i= 0; i < L; i++)
		{
			Path = Path + Forme[i];
		}
		return Path;
	}

	/*///////////////###########  TOUTES LES 24 IMG SEC ON REECRITS LE TABLEAU QUI CONTNEINT LE TRACE EN FOCNTION DES NOUVELLE VALEUR DES POINT############////////////////////*/
	function creatabform(){
		/*/////////////////  X = 0 //// Y = 1 /// Besier X = 2 ///// Besier Y = 3*/
		var Forme = [];/*////////////////////////////////////////////////////////// avant tout vide le array*/
		var nbP = tabformul.length;
		Forme.push("M"+tabformul[0][0].valactuel+","+tabformul[0][1].valactuel);/*//////////////////// ///////////////  place le curseur sur le XY du point 0*/

		for(var P= 1; P < nbP; P++)
		{
			var nbc = tabformul[P].length;
			if(nbc===2){/*///////////// soit une ligne */
			Forme.push("L"+tabformul[P][0].valactuel+","+tabformul[P][1].valactuel);/*////////// du haut a droite jusqu'a mileu  B3 // P2*/
			}
			if(nbc===4){/*//////////// soit une Curve smooth */
			Forme.push("S"+tabformul[P][0].valactuel+","+tabformul[P][1].valactuel+" "+tabformul[P][2].valactuel+","+tabformul[P][3].valactuel);/*////////// du haut a droite jusqu'a mileu  B3 // P2*/
			}
			if(nbc===6){/*//////////// soit une Curve*/
			Forme.push("C"+tabformul[P][0].valactuel+","+tabformul[P][1].valactuel+" "+tabformul[P][2].valactuel+","+tabformul[P][3].valactuel+" "+tabformul[P][4].valactuel+","+tabformul[P][5].valactuel);/*////////// du haut a droite jusqu'a mileu  B3 // P2*/
			}
		}
		Forme.push("Z");/*////// ferme la forme*/

		return Forme;
	}

	/*///////////////###########  EFFACE ET REDESSINE DANS LE CANVAS LE NOUVEAU TRACE ############////////////////////	*/
	function creatsvg(Forme){
		if(!document.getElementsByClassName(cibleannim)[0]){
			AnniEnCours = false;
			return;
		}
		if(currentpath===creapath(Forme)){
			combiendeidentique++;
		}else{
			combiendeidentique=0;
		}
		if(combiendeidentique>=20){
			AnniEnCours = false;
			return false;
		}
		currentpath = creapath(Forme);
		//console.log(Forme);
		document.getElementsByClassName(cibleannim)[0].getElementsByClassName('annimsvg')[0].getElementsByClassName('mypath')[0].setAttribute('d',currentpath);
		//fpsMeter.tick();
		animationFrame.request(Passtime);
	}

	function annim(tabformultoannim, cibletoannim){
		//console.log(tabformultoannim+', '+cibletoannim+' '+AnniEnCours);
		tabformul = tabformultoannim;
		if(AnniEnCours){
			return false;
		}
		cibleannim = cibletoannim;
		AnniEnCours = true;
		animationFrame.request(Passtime);
	}

	function Passtime(){
		checktabformul(clock.gettime());
		clock.ticktak(FrameRate);
		//TheTimeis = TheTimeis + FrameRate;
	}

	function checktabformul(TheTimeis){
		var nbP = tabformul.length;
		for(var P= 0; P < nbP; P++)
		{
			var nbc = tabformul[P].length;
			for(var XY= 0; XY < nbc; XY++)/*////////////// pour chaque point trace un lien*/
			{
				//console.log(tabformul[P][XY].tpsdep*1000+' <= '+TheTimeis+'&&'+tabformul[P][XY].tpsend*1000+'>='+TheTimeis);
				if(tabformul[P][XY].tpsdep*1000 <= TheTimeis && tabformul[P][XY].tpsend*1000 >= TheTimeis){
					var tab = tabformul[P][XY].tabvar.split(",");
					tabformul[P][XY].valactuel = milkytween.tween(TheTimeis,tab);
					//console.log((tabformul[P][XY].tpsend*1000)+'<='+TheTimeis);
					if(tabformul[P][XY].tpsend*1000<=TheTimeis){/*//////// CALL BACK*/
					//	console.log('belors'+tabformul[P][XY].CALLBACK);
						if(tabformul[P][XY].CALLBACK){
							tabformul[P][XY].CALLBACK();
						//	tabformul[P][XY].CALLBACK = null;
						}
					}
				}
			}
		}

		creatsvg(creatabform());

	}


    return {
        annim:annim
    };

});

define("svgbg/annimsvg", function(){});

