/*** admin swiper* * @author Author name* @date 2014-23-12*/
define('getpos', [], function () {
	'use strict';

	function getxycercle(X,Y,R,A){
		var angleRadian = A * Math.PI / 180;
	    var rX = X+ Math.round(Math.cos(angleRadian) * R);
	    // Sur l'axe Y utiliser le sinus de l'angle
	    var rY = Y+ Math.round(Math.sin(angleRadian) * R);
		var tab = [rX,rY];
		return tab;
	}

	function getdistancepoint(x,y,x1,y1){
		return Math.round(Math.sqrt((Math.pow((x1-x),2))+(Math.pow((y1-y),2))));
	}

	function getangle(x,y,x1,y1,rad){
		var angleRadian = Math.atan2(y1- y, x1 -x);
	    var ret = angleRadian;
	    if(rad===undefined){
			// Convertir l'angle radians en degrés.
			ret = angleRadian * 180 / Math.PI;
	    }
	    return ret;
	}
	function getmilieu(x,y,x1,y1){
		var rayon = getdistancepoint(x,y,x1,y1)/2;
		var Angle = getangle(x,y,x1,y1);
		var ret = getxycercle(x,y,rayon,Angle);
		return ret;
	}

    return {
        getxycercle:getxycercle,getdistancepoint:getdistancepoint,getmilieu:getmilieu,getangle:getangle
    };

});

define("svgbg/getpos", function(){});

