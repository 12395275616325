/**
 * An example module
 *
 * @author Author name
 * @date 2013-08-18
 */

define('sender', ['jquery'], function ($) {

	// Strict mode to prevent sloppy JS
	'use strict';

	function renderData() {
        console.log("inside callback");
    }

    function getData(options) {
        $.ajax({
	        type: options.Type,
	        url: options.apiURL,
			data: options.Data,
	        dataType: options.format,
	        //method: options.method,
	        jsonpCallback: options.jsonpCallback,
	        success: options.successCallback || renderData,
	        error: options.Error,
	        timeout: options.TimeOut
        });
    }

    return {
        getData: getData
    };

});

define("modules/sender", function(){});

